/* eslint-disable prefer-rest-params */
import React from 'react';
import ReactDOM from 'react-dom';
import { enableMapSet } from 'immer';
import * as Sentry from '@sentry/react';
import '@CSS/index.css';
import './app.pcss';
import '@CSS/thirdParty';
import enableVersion from '@Services/Scripts/Versions';
import { catchFragmentRemoval } from 'render-utils/tools/utility';
import { handleSentryBeforeSend } from './sentryHandler';

const { VITE_SENTRY_DSN, VITE_NODE_ENV, VITE_API_URL_V2, VITE_APP_VERSION } = import.meta.env;

enableMapSet();
enableVersion();

async function renderMainApp() {
  //! Fragment removal errors (caused by google translate)
  //* See: https://github.com/facebook/react/issues/11538#issuecomment-417504600
  catchFragmentRemoval();

  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    beforeSend: handleSentryBeforeSend,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [VITE_API_URL_V2],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // [httpClientIntegration] This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    environment: VITE_NODE_ENV,
    release: VITE_APP_VERSION,
  });

  if ('serviceWorker' in navigator) {
    console.log('Registering service worker');
    navigator.serviceWorker.register('/cacheFontServiceWorker.js');
  } else {
    console.warn('Service workers are not supported in this browser.');
  }

  const mainAppProvider = (await import('@Providers/MainApp')).default;
  const App = await mainAppProvider();
  ReactDOM.render(<App />, document.getElementById('root'));
}

renderMainApp();
